import React, { Component } from "react"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
const { BLOCKS, MARKS } = require('@contentful/rich-text-types');

const options = {
  renderMark: {
    [MARKS.BOLD]: text => `<b>${text}</b>`
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) =>
      `<p>${next(node.content)}</p>`
  }
}
class RichText extends Component {
  render() {
    const body = documentToHtmlString(this.props.content, options);
    return <div dangerouslySetInnerHTML={{ __html: body }} />;
  }
}

RichText.propTypes = {};

export default RichText;